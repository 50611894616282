import React from "react";
import axios from "axios";
import currencyFormatter from "currency-formatter";
import "./donate.scss";
import { getAnalytics } from "../api/getDonationAnalytics";

class Donate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      raised: 0,
      donors: 0,
    };
    this.getDonorTotals = this.getDonorTotals.bind(this);
  }

  componentDidMount() {
    this.getDonorTotals();
  }

  async getDonorTotals() {
    const data = await getAnalytics();
    if (data !== undefined && data !== null) {
      this.setState({
        raised: data.response.raised,
        donors: data.response.donors,
      });
    }
  }

  render() {
    return (
      <div className="donate-container">
        <div className="row">
          <div className="column">
            <div className="donate-container-total">
              <h1>
                {currencyFormatter.format(this.state.raised, {
                  code: "USD",
                  precision: 0,
                })}
              </h1>
              <p>RAISED</p>
            </div>
          </div>
          <div className="column">
            <div className="donate-container-donors">
              <h1>{this.state.donors}</h1>
              <p>DONATIONS*</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Donate;
