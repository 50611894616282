import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Router} from 'react-router-dom';
import App from './App';
import DonateForm from './pages/page-donate.js';
import DonateACHForm from './pages/page-donate-ach.js';
import ThankYouPage from './pages/page-thank-you.js';
import ThankYouACHPage from './pages/page-thank-you-ach.js';
import DonateTransferPage from './pages/page-donate-transfer.js';
import Terms from './pages/page-terms.js';
import * as serviceWorker from './serviceWorker';
import history from './history';

const routing = (
  <Router history={history}>
    <div>
      <Route exact path="/" component={App} />
      <Route exact path="/donate" component={DonateForm}/>
      <Route exact path="/donate-ach" component={DonateACHForm}/>
      <Route exact path="/donate-transfer" component={DonateTransferPage}/>
      <Route exact path="/donation-thank-you" component={ThankYouPage}/>
      <Route exact path="/donation-thank-you-ach" component={ThankYouACHPage}/>
      <Route exact path="/terms" component={Terms}/>
    </div>
  </Router>
)

ReactDOM.render(routing, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
