import axios from "../utils/axios";

export const getCheckout = async (
  amount,
  currency,
  charityId,
  name,
  email,
  customerCoveringFee
) => {
  const {
    data: { response, error },
  } = await axios.post(
    `/.netlify/functions/fetch-stripe-checkout`,
    { amount, currency, charityId, name, email, customerCoveringFee },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );

  return { error, response };
};
