import React, { Component } from "react";
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Donate from "./components/donate.js";
import DonationOptions from "./components/donation-options.js";
import LearnMoreButton from "./components/learn-more.js";
import SocialComponent from "./components/social-component.js";
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from "./images/hero_image.png";
import bottomImage from "./images/bottom_image.png";
import "./App.scss";
const data = require("./data/data.json");

class App extends Component {
  render() {
    return (
      <div>
        <Header />
        <div className="row">
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                <div className="section-main-title">
                  <img src={heroImage}></img>
                  <div style={{ minWidth: "300px" }}></div>
                  <div className="topTitle">
                    <h1>{data.title}</h1>
                  </div>
                </div>

                <div className="section-main-description">
                <p>Amid the holiday season, the Eduro Employee Fund continues to bring our community together to extend condolences and support to one of our own. We stand in solidarity with Katie Estrada and family as they grieve the loss of her husband, Josh Mummert.</p>

                <p>On December 9th, Josh, beloved husband and father, was sitting at the kitchen table when he stopped breathing and fell to the floor. Despite the tireless efforts of emergency medical services persisting for over an hour, their valiant attempts proved unsuccessful. Josh succumbed to a massive heart attack, leaving behind his grieving wife, Katie, and their four children, a family now navigating the depths of heartbreak.</p>

                <p>As we’ve expressed before, the Eduro Employee Fund was created for devastating times like these, so we can quickly lend our support as a community and family. We are committed to being there for one another and uplifting our team members when they need it most, especially for those like Katie, a dedicated employee who is always willing to lend a helping hand.</p>

                <p>Every donation, no matter the size, makes a significant impact. The contributions collected will go directly to the Eduro Employee Fund, which will help Katie and her children. As Josh was the main provider for their family and Katie worked for groceries, any financial assistance to help Katie care for their four children is greatly appreciated. While Katie’s family’s pain is unimaginable, may our support help alleviate some of the financial strain and send a reminder that we are here for them in any way that we can as they navigate this difficult time.</p>

                <p><b>Here's how you can make a difference:</b></p>

                <p><b>Donate:</b> Make a contribution in any amount you feel comfortable with. Remember, every bit helps.</p>

                <p><b>Spread the Word:</b> Share this initiative with your colleagues, friends, and family by sharing this link amplifying our collective efforts to assist those in need.</p>

                <p><b>Send Your Well Wishes:</b> Alongside your donation, we encourage you to send your messages of support and encouragement to Katie. Your words of comfort can provide much-needed strength.</p>

                <p>We have launched this Eduro Employee fundraising campaign with the aim of reaching $5,000 over the next few weeks. All donations will be tax-deductible.</p>

                <p>Thank you for embodying the spirit of compassion that makes our Eduro community truly special. Together, we can make a meaningful impact and continue to showcase the power of unity in times of adversity.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <Donate />
              <DonationOptions stripeAction={this.showModal} />
              <SocialComponent />
              <div class="section-donate-disclaimer">
                *While we work to ensure accuracy, the stats indicated on this
                page may not be an exact reflection of actual activity.
                <br />
                ** If you would like to make a large donation with a different
                payment method, please reach out to Givinga at
                support@givinga.com
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
